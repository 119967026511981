.main-header{
    padding: 0.7rem 6rem;
    padding-top: 2rem;
    color: #fff;
    background-color: #2d784e;
}
.main-header h1{
    font-size: 2.3rem;
    margin-bottom: 0.3rem;
}
.main-header h3{
    font-size: 1.6rem;
    /* color: #2d784e; */
}

/* #2d784e  */
/* #312F44  */
@media(max-width:1000px){
    .main-header h1{
        font-size: 1.5rem;
    }
    .main-header h3{
        font-size: 1.3rem;
    }
}
@media(max-width:700px){
    .main-header{
        padding-inline: 1rem;
    }
    .main-header h1{
        font-size: 1.2rem;
    }
    .main-header h3{
        font-size: 1rem;
    }
}