.messages-list{
    padding-block: 2rem;
    padding-inline: 6rem;
    min-height: 100vh;
}
.loader-container{
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-container img{
    height: 50vh;
    width: 30vw;
    margin-right: 12rem;
    animation: loader 0.7s linear alternate infinite;
}
.no-messages{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}
.no-messages h1{
    font-size: 1.5rem;
    color: #312F44;
}
@keyframes loader{
    from{
        transform: scale(1.1);
        opacity: 1;
    }
    to{
        transform: scale(1);
        opacity: 0.3;
    }
}
@media(max-width:950px){
    .messages-list{
        padding-inline: 3rem;
    }
    .loader-container img{
        height: 30vh;
    }
}
@media(max-width:750px){
    .messages-list{
        padding-inline: 1rem;
    }
}
@media(max-width:650px){
    .loader-container{
        height: 20vh;
        width: 100vw;
    }
    .loader-container img{
        height: 10vh;
        width: 15vw;
    }
}