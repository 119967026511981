.open-message{
    padding: 1rem 3rem;
    color: #312F44;
    border: 2px solid #312F44;
    margin: 1rem 6rem;
    min-height: 70vh;
    border-radius: 10px;
}
.open-message h1{
    font-size: 2.5rem;
    margin-bottom: 2rem;
}
.open-message h3{
    margin-bottom: 2rem;
    font-size: 1.5rem;
}
.open-message h5{
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}
.open-message p{
    font-size: 1rem;
}
.open-message hr{
    height: 0.08rem;
    border: none;
    width: 100%;
    background-color: #312F44;
    margin-bottom: 1rem;
}
@media(max-width:900px){
    .open-message{
        margin-inline: 2rem;
        padding-inline: 2rem;
    }
}
@media(max-width:750px){
    .open-message h1{
        font-size: 2rem;
    }
    .open-message h3{
        font-size: 1.4rem;
    }
}
@media(max-width:550px){
    .open-message{
        margin-inline: 1rem;
        padding-inline: 1rem;
    }
    .open-message h1{
        font-size: 1.5rem;
    }
    .open-message h3{
        font-size: 1.1rem;
    }
    .open-message h5{
        font-size: 1.1rem;
    }
}