.message{
    background-color: hsl(0, 0%, 97%);
    color: #312F44;
    width: 100%;
    padding: 1rem 5rem;
    border-radius: 8px;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    cursor: pointer;
}
.message h3{
    font-size: 1.6rem;
    margin-bottom: -0.1rem;
}
.message p{
    font-size: 1rem;
    color: #464454;
}
.message img{
    width: 100px;
    height: 90px;
    border-radius: 50%;
}
@media(max-width: 800px){
    .message{
        padding: 1rem 2rem;
    }
    .message h3{
        font-size: 1.3rem;
    }
    .message p{
        font-size: 0.9rem;
    }
}
@media(max-width:550px){
    .message{
        padding: 1rem 2rem;
    }
    .message h3{
        font-size: 1.1rem;
        margin-bottom: 0.2rem;
    }
    .message p{
        font-size: 0.8rem;
    }
    .message img{
        width: 70px;
        height: 50px;
    }
}